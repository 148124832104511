<!--  -->
<template>
    <div class="homecont">
      <HeadNav></HeadNav>
      <div class="homeLun">
        <img class="wecardImg"  src="../assets/some/16.png" alt="zhanwei"/>
      </div>
      <div class="homewe">
        <div class="homeweTop">
            <!-- <img class="homeweTopLft" src="../assets/new/map.png" /> -->
            <div class="homeweTopLft" >
              <baidu-map class="map" :ak="ak" @ready="handleMapReady" :center="mapCenter" :zoom="zoom">
                <bm-marker :position="markerPosition"></bm-marker>
              </baidu-map>
            </div>


            <div class="homeweTopRig">
                <img src="../assets/some/17.jpg" alt="zhanwei2"/>
                <div class="homeweTopRig-btm">
                    <!-- <div>CONTACT</div> -->
                    <div>Telephone：+86-21-62377015</div>
                    <div>Email：Weiqiuling@junkotech.com </div>
                    <div>Company Address：Room 1108,Xinda building,No.322Xianxia Road,Changning District,Shanghai</div>
                </div>
            </div>
        </div>

        <div class="homeWoBox">
          <div class="homeWoBox-title">Online message</div>
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <el-form-item label="Name" prop="name">
                    <el-input v-model="ruleForm.name"></el-input>
                </el-form-item>
                <el-form-item label="Mobile" prop="phone">
                    <el-input v-model="ruleForm.phone"></el-input>
                </el-form-item>
                <el-form-item label="Email" prop="email">
                    <el-input v-model="ruleForm.email"></el-input>
                </el-form-item>
                <el-form-item label="Country" prop="country">
                    <el-input v-model="ruleForm.country"></el-input>
                </el-form-item>
                <el-form-item label="Leaving a message" prop="message" class="ceshidiv">
                    <el-input v-model="ruleForm.message" type="textarea" :rows="2"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitForm('ruleForm')">Submit</el-button>
                </el-form-item>
            </el-form>
        </div>
      </div>
      <Bottom></Bottom>
      <Sidebar></Sidebar>
    </div>
  </template>
  
  <script>
    import {Dialog} from 'vant';
  import { APILogin } from "../api/APILogin";
  import HeadNav from "../components/headNav";
  import Bottom from "../components/bottom";
  import Sidebar from "../components/sidebar";
  export default {
    metaInfo: {
      title: 'contact us',
      meta: [
        {
          name: 'contact us',
          content: 'SiC powder,Induction Heating Machine,Induction Heating power supply,water Cooling system,Disk CNC quenching machine tool,quenching machine tool,High precision power supply'
        },
        {
          name: 'description',
          content: ' Shanghai Junko New Energy Technology Co., Ltd. is a high-tech enterprise located in Changning District, Shanghai, focusing on the research and development, production, and sales of induction heating technology and semi-conductor materials. We provide efficient and energy-saving induction heating equipment to help improve industrial production efficiency, focus on innovation in semiconductor materials,promote the green energy revolution, and con-tribute to global sustainable development.'
        }
      ]
    },
    name: "homeWo",
    components: {
      HeadNav,
      Bottom,
      Sidebar,
    },
    data() {
      return {
        ak: 'kfoQJcsHfEY4iq6C8ZaNQdybZTp6JC9Z',
        //121.405637,31.211255
        mapCenter: { lng: 121.405637, lat: 31.211255 },
        zoom: 15,
        markerPosition: { lng: 121.405637, lat: 31.211255 },


        windowHeight: (document.documentElement.clientHeight - 100),   //实时屏幕高度
        newList: [
          {
            id: 1,
            name: '高纯sic粉',
            name2: '第三代半导体材料——SIC  高纯度、导电型',
            url: require("../assets/new/c1.jpeg"),
            showOverlay: false,
          }, {
            id: 2,
            name: '回转支承滚道数控机床成套设备',
            name2: 'Inclined vertical raceway CNC',
            url: require("../assets/new/c2.png"),
            showOverlay: false,
          },{
            id: 3,
            name: '双频电源',
            name2: 'Gears, sprockets, slewing supports, etc. need profiling heating;',
            url: require("../assets/new/c3.png"),
            showOverlay: false,
          },{
            id: 4,
            name: '便携式感应加热电源',
            name2: 'The intelligent digital induction heating power supply has a high-speed core processor of 100 megabytes，which can calculate and process all digital signals',
            url: require("../assets/new/c4.png"),
            showOverlay: false,
          },{
            id: 5,
            name: '晶体生长感应加热电源',
            name2: 'Specially designed for crystal growth furnace',
            url: require("../assets/new/c5.png"),
            showOverlay: false,
          },
        ],


        ruleForm: {
          name: '',
          country: '',
          email: '',
          message: '',
          phone: '',
        },
        rules: {
          name: [
            { required: true, message: '请输入名称', trigger: 'blur' },
          ],
          phone: [
            { required: true, message: '请输入电话', trigger: 'blur' },
          ],
          email: [
            { required: true, message: '请输入邮箱', trigger: 'blur' },
          ],
          message: [
            { required: true, message: '请输入留言', trigger: 'blur' },
          ],
        },




      }
    },
    // 监听属性 类似于data概念
    computed: {},
    // 监控data中的数据变化
    watch: {
      // windowHeight (val) {
      //   let that = this;
      //   //console.log("实时屏幕高度：",val, that.windowHeight );
      // },
      
    },
    filters: {},
    //方法集合
    methods: {
      handleMapReady(map) {
      // 地图加载完成后的回调函数
      console.log('地图加载完成', map);
    },
        // 提交留言
        submitForm() {
            APILogin.messageAdd({
                name: this.ruleForm.name, 
                country: this.ruleForm.country,
                email: this.ruleForm.email, 
                message: this.ruleForm.message,
                phone: this.ruleForm.phone, 
            }).then(res => {
                Dialog.alert({
                    message: 'Thank you for your message. Someone will contact you later',
                })
                console.log("res>>>>" + JSON.stringify(res.data))
            }).catch(fail => {
                console.log("fail>>>>" + JSON.stringify(fail))
            }) 
        },


      handleMouseEnter(item) {
        //console.log("111>>>>",JSON.stringify(item.id))
        let idval = item.id
        this.newList.map(it=>{
          if(it.id == idval) {
            it.showOverlay = true
          }else {
            it.showOverlay = false
          }
        })
      },
      handleMouseLeave() {
        this.newList.map(item=>{
          item.showOverlay = false
        })
      },
  
  
      choseClick(item) {
          console.log(">>>>>>>",JSON.stringify(item))
          this.$router.push({ 
              path: '/pcyaoqwenDetails', 
              query: { 
                  auditID: item.id,  
              } 
          });
      },
    },
    // 生命周期 - 创建完成（可以访问当前this实例）
    created() {
      document.title = "联系我们"
    },
    //生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {
      //var that = this
      // <!--把window.onresize事件挂在到mounted函数上-->
      // window.onresize = () => {
      //   return (() => {
      //     window.fullHeight = document.documentElement.clientHeight
      //     that.windowHeight = Number(window.fullHeight)  // 高
      //     console.log(">>>>>",that.windowHeight)
      //   })()
      // }

      // 其他地图操作...  

    },
    beforeCreate() {}, // 生命周期 - 创建之前
    beforeMount() {}, // 生命周期 - 挂载之前
    beforeUpdate() {}, // 生命周期 - 更新之前
    updated() {}, // 生命周期 - 更新之后
    beforeDestroy() {}, // 生命周期 - 销毁之前
    destroyed() {}, // 生命周期 - 销毁完成
    activated() {}, // 如果页面有keep-alive缓存功能，这个函数会触发
  };
  </script>
  <style lang="less" scoped src="@/styles/pc/home.less"></style>
  <style lang="less" scoped src="@/styles/pc/homewe.less"></style>
  <style lang="less">
      .ceshidiv {
      .el-form-item__label {
        line-height: 16px;
        margin-top: 10px;
      }
    }
    .chanpinBoxTitleTop {
      .el-carousel__item:hover .chanpinBoxContShuo{
          cursor: pointer;
          opacity: 1;
          border: 1px solid rgba(0, 0, 0,.3);
      }
      .is-active {
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0,.6);
        border-radius: 5px;
        
      }
      .el-carousel__indicators--outside {
        visibility: hidden;
      }
      
    }
  .homeWoBox {
    .el-button--primary {
      width: 100%;
    }
    .el-form-item__label {
      line-height: 24px;
    }
  }
  </style>
  
  
  
  